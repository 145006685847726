<template>
  <div>
    <stimulsoft-editor ref="editor" emitir-download />
  </div>
</template>
<script>
import { StimulsoftEditor } from '@components/misc';
export default {
  components: {
    StimulsoftEditor,
  },
  mounted: function () {
    this.$refs.editor.montarComponente();
  },
};
</script>
